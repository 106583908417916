import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../../shared/GatsbyButton';

const Copy = styled(Paragraph)`
  margin: 0 0 60px;
`;

const TermsOfUse = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO path={CONST.LINKS.MAIN.LEGAL.SITE_ACCESSIBILITY} title="Site accessibility" description="Information about Beeline's site accessibility." />
    <UpdateAt date="April 2020" />
    <Container>
      <H2>Site Accessibility</H2>
      <Copy>
        Beeline is dedicated to making our websites accessible to everyone, including individuals with disabilities. To report a problem or request an accommodation, please
        contact&nbsp;
        <ButtonText to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
        </ButtonText>{' '}
        or reach us by phone at <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.BEELINE_POPUP}`}>{CONST.LINKS.EXTERNAL.PHONE.BEELINE_DISPLAY}</ButtonText>. In your message,
        please include the web page address or URL and the specific problems you have encountered.
      </Copy>
    </Container>
  </NavOnlyTemplate>
);

export default TermsOfUse;
